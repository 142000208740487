<template>
    <div class="u-bg-color-full-screen">
        <div class="u-align-error-page">
            <span class="u-text-figure u-text-white">{{
                PROVIDER.ErrorPage.ErrorMessages.NoAccess
            }}</span>
            <h3 class="u-text-white pt-4">
                {{ PROVIDER.ErrorPage.ErrorMessages.UnauthorizedProvider }}
            </h3>
        </div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import UserService from '../../../shared/services/UserService'

export default {
    name: 'ErrorPage',
    data() {
        return {
            timeout: null,
            PROVIDER,
            userService: new UserService()
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.timeout = setTimeout(() => {
                this.userService.signOut()
            }, 5000)
        })
    },
    beforeDestroy() {
        clearTimeout(this.timeout)
    }
}
</script>
